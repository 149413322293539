import { render, staticRenderFns } from "./SelectPropertyTypeDropdownComponent.vue?vue&type=template&id=d399851c&scoped=true"
import script from "./SelectPropertyTypeDropdownComponent.vue?vue&type=script&lang=js"
export * from "./SelectPropertyTypeDropdownComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d399851c",
  null
  
)

export default component.exports